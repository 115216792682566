import { DataTableSortConfigButton, DataTableSortConfigButtonAriaLabel } from '../types/dataTableSort.types';

/**
 *
 * Custom hook to manage sorting functionality for a data table.
 *
 * @name Sort
 * @constants
 */

/**
 * Enum that defines the possible sorting directions for a data table.
 *
 * @enum {string}
 * @readonly
 */
export enum SORT_DIRECTION {
  ASC = 'asc',
  DESC = 'desc',
  NONE = 'none'
}

/**
 * Default titles for sort buttons based on sorting directions.
 *
 * These titles provide default text for sort buttons, based on the sorting direction of the column.
 * They are used to generate user-friendly button titles for sorting actions.
 *
 * @property {Object.<SORT_DIRECTION, (columnTitle: string) => string>} DataTableSortConfigButton
 */
export const DEFAULT_SORT_BUTTON_TITLES: DataTableSortConfigButton = {
  [SORT_DIRECTION.ASC]:
    (columnTitle: string) => `Clique para ordenar a coluna ${columnTitle} em ordem descentente.`,
  [SORT_DIRECTION.DESC]:
    (columnTitle: string) => `Clique para remover a ordenação da coluna ${columnTitle}`,
  [SORT_DIRECTION.NONE]:
    (columnTitle: string) => `Clique para ordenar a coluna ${columnTitle} em ordem ascendente.`,
};

/**
 * Default ARIA labels for sort buttons based on sorting directions.
 *
 * These ARIA labels provide default accessibility labels for sort buttons, based on the sorting direction of the column.
 * They are used to enhance the accessibility of sorting actions.
 *
 * @property {Object.<SORT_DIRECTION, (columnTitle: string) => string>} DataTableSortConfigButtonAriaLabel
 */
export const DEFAULT_SORT_BUTTON_ARIA_LABELS: DataTableSortConfigButtonAriaLabel = {
  [SORT_DIRECTION.ASC]:
    (columnTitle: string) => `Coluna ${columnTitle} ordenada de forma ascendente,
    clique para ordenar de forma descendente ou remover a ordenação`,
  [SORT_DIRECTION.DESC]:
    (columnTitle: string) => `Coluna ${columnTitle} ordenada de forma descendente,
    clique para remover a ordenação ou alternar para ordenação ascendente`,
  [SORT_DIRECTION.NONE]:
    (columnTitle: string) => `Coluna ${columnTitle} sem ordenação,
    clique para ordenar de forma ascendente`,
};
