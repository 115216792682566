import React from 'react'
import { classesBuilder } from 'shared/utils'

import ContainerType from './types/container.type'

import * as style from 'theme/context/container/container.module.scss'

const Container: React.FC<ContainerType> = ({ children }) => (
  <div className={classesBuilder(style, { container: true })}>
    <div className={classesBuilder(style, { wrapper: true })}>{children}</div>
  </div>
)

export default Container
