import DataType from 'components/webform/select/types/data.type'
import React, { ReactNode } from 'react'
import FloatLayer from 'shared/components/floatLayer'
import { classesBuilder } from 'shared/utils/index'
import * as style from 'theme/components/webform/select/select.module.scss'
import BaseList, { ColumnSelectOption } from './base'
import Search from './search'

type ListSingleType = {
  searchable?: boolean
  label?: string
  onClick?: Function
  show: boolean
  getElement: ({}: HTMLDivElement) => void
  isRequestable: boolean
  onSearch: (...item: any) => void
  useContentList?: DataType[] | null
  useTerm: string
  maxHeight: number
  selecteds: DataType[] | null
  fixedList?: boolean
  customOption?: (option: DataType, isSelected: boolean) => ReactNode
  columns?: ColumnSelectOption[]
  customMessageInitial?: string
  customMessageNotFound?: string
  isRequest?: boolean
  requestFinished?: boolean
}

const ListSingle: React.FC<ListSingleType> = ({
  customOption,
  fixedList,
  getElement,
  label,
  onClick,
  onSearch,
  searchable,
  selecteds,
  show,
  useContentList,
  useTerm,
  columns,
  customMessageInitial,
  customMessageNotFound,
  isRequest,
  requestFinished,
}) => (
  <FloatLayer
    elevation={5}
    full
    outlined
    rounded
    show={show}
    title={label ?? 'Selecione'}
    header={<div>{searchable && <Search onSearch={onSearch} term={useTerm} />}</div>}
    fixed={fixedList}
  >
    <div className={classesBuilder(style, { table: columns?.length, list: !columns?.length })}>
      <div ref={getElement}>
        <BaseList
          columns={columns}
          customOption={customOption}
          data={useContentList}
          onClick={onClick}
          selecteds={selecteds}
          isRequest={isRequest}
          requestFinished={requestFinished}
          customMessageInitial={customMessageInitial}
          customMessageNotFound={customMessageNotFound}
        />
      </div>
    </div>
  </FloatLayer>
)

export default ListSingle
