import React, { MouseEvent, ReactNode } from 'react'

import Icon from 'shared/components/icon'
import Loader from 'shared/components/loader'
import { classesBuilder, getTextCapitalize } from 'shared/utils'

import ButtonType from './types/button.type'

import usePositions from './hooks/usePositions'

import { uniqueId } from 'lodash'
import * as style from 'theme/components/button/button.module.scss'

const Button: React.FC<ButtonType> = ({
  bold,
  color = 'colorDefault',
  children,
  disabled,
  full,
  icon,
  iconDir = 'left',
  iconColor = 'clear',
  invertColor,
  label,
  loading,
  loaderType,
  ripple = true,
  rounded,
  outlined,
  shadow,
  solo,
  upperCase,
  size = 'medium',
  onClick,
  submit,
  href,
  download,
  target,
  testId
}) => {
  const { coords, isRippling, setCoords, setIsRippling, rippleEffectTimeRef } = usePositions()

  const isOnClick = onClick instanceof Function

  const btnProps = {
    bold,
    button: true,
    [color || '']: !disabled && color,
    [size || '']: size,
    [`icon${getTextCapitalize(iconDir)}`]: iconDir && label,
    pointer: isOnClick || submit,
    disabled,
    full,
    iconOnly: !label,
    invertColor,
    loading,
    ripple: ripple && isOnClick,
    rounded,
    outlined,
    shadow,
    solo,
    upperCase,
  }

  const getTestId = () => {
    return testId ? `button_${testId}` : uniqueId('button_');
  }

  const handleClick = (event: MouseEvent): void => {
    handleRippleEffect(event)
    onClick?.()
  }

  const handleRippleEffect = (event: MouseEvent): void => {
    if (btnProps?.ripple) {
      const button = event.target as HTMLInputElement
      const { left, top } = button.getBoundingClientRect()

      const { clientX, clientY } = event

      setCoords({ x: clientX - left, y: clientY - top })
      setIsRippling(true)

      rippleEffectTimeRef.current = window.setTimeout(() => {
        setIsRippling(false)
        setCoords(null)
      }, 500)
    }
  }

  const renderButtonOrAnchor = (content: ReactNode): ReactNode => {
    if (href) {
      return (
        <a
          className={classesBuilder(style, btnProps)}
          href={ href }
          target={ target }
          download={ download }
        >
          { content }
        </a>
      )
    }
    return (
      <button
        disabled={disabled}
        onClick={ripple ? handleClick : onClick}
        className={classesBuilder(style, btnProps)}
        type={submit ? 'submit' : 'button'}
        data-testid={getTestId()}
      >
        { content }
      </button>
    )
  }

  return renderButtonOrAnchor(
    <>
      {loading ? (
          <div className={classesBuilder(style, { loader: true })}>
            <Loader type={loaderType} />
          </div>
        ) : (
          icon && <Icon name={icon} color={iconColor || color} />
        )}

        {children || label}

        {isRippling && (
          <span
            className={classesBuilder(style, { rippleEffect: true })}
            style={{
              left: coords.x,
              top: coords.y,
            }}
          />
        )}
    </>
  );
}

export default Button
