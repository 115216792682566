import React from 'react'

import classesBuilder from 'shared/utils/classesBuilder'

import Icon from 'shared/components/icon'

import { TabsType, TabType } from './types/tabs.type'

import UseTabs from './hooks/useTabs'

import ToggleContent from './components/toggleContent'

import * as style from 'theme/components/tabs/tabs.module.scss'

const Tabs: React.FC<TabsType> = ({
  onChange,
  actions = [],
  solo,
  rounded,
  outlined,
  full,
  center,
  iconDir = 'right',
  elements,
}) => {
  const { changeTab, useTabs, useTabsStyle, tabsRef } = UseTabs(onChange, actions, !!solo)

  const menuTabClassNames = {
    tabMenu: true,
    rounded,
    solo,
    outlined,
    styleDefault: !solo && !rounded && !outlined,
  }

  const animationsClassNames = {
    animation: true,
    transition: useTabs.length,
    outlined,
    rounded,
    styleDefault: !solo && !rounded && !outlined,
  }

  return (
    <div
      className={classesBuilder(style, {
        wrapper: true,
        full,
        center,
      })}
    >
      <div className={classesBuilder(style, menuTabClassNames)}>
        <div className={classesBuilder(style, { tabsWrapper: true })}>
          {useTabs.map((tab: TabType, index) => (
            <button
              {...(!tab?.disabled && {
                onClick: (event) => changeTab(event, index),
              })}
              ref={(element: HTMLButtonElement) => tabsRef.current?.push(element)}
              key={index}
              className={classesBuilder(style, {
                focus: tab.active,
                [iconDir]: tab?.icon,
                disabled: tab?.disabled,
              })}
              type='button'
            >
              {tab?.label}
              {tab?.icon && <Icon name={tab.icon} size={16} />}
            </button>
          ))}

          <div
            className={classesBuilder(style, animationsClassNames)}
            style={{ ...useTabsStyle }}
          >
            <div className={classesBuilder(style, { curve: true })}/>
            <div className={classesBuilder(style, { curve: true })}/>
          </div>
        </div>
      </div>

      <div className={classesBuilder(style, { tabView: true })}>
        {useTabs.map((tab: TabType, index: number) => (
          <ToggleContent key={index} visible={tab?.active || false}>
            {elements[index]}
          </ToggleContent>
        ))}
      </div>
    </div>
  )
}

export default Tabs
