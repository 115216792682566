import React, { useMemo } from 'react'

import ToggleType from './types/toggle.type'
import classesBuilder from 'shared/utils/classesBuilder'
import Icon from 'shared/components/icon'
import * as style from 'theme/components/webform/toggle.module.scss'

const Toggle: React.FC<ToggleType> = ({
  disabled,
  checked,
  handleChange,
  small,
  medium,
  large,
  iconRight,
  iconLeft,
}) => {
  const handleClick = (): void => handleChange?.(checked)

  const sizeProps = { small, medium, large }
  const toggleProps = { ...sizeProps, switch: true }

  const iconSize = useMemo(() => {
    if (small) {
      return 12
    }

    if (large) {
      return 22
    }

    return 15
  }, [small, large])

  return (
    <label
      className={classesBuilder(style, {
        ...toggleProps,
      })}
    >
      <input
        type="checkbox"
        className={classesBuilder(style, { input: true })}
        disabled={disabled}
        checked={checked}
        onChange={handleClick}
      />
      <span className={classesBuilder(style, { slider: true })}></span>
      {iconLeft ? (
        <div
          className={classesBuilder(style, {
            switchIcon: true,
            switchIconLeft: true,
            ...sizeProps,
          })}
        >
          <Icon name={iconLeft} color={checked ? 'clear' : 'light'} size={iconSize} />
        </div>
      ) : null}

      {iconRight ? (
        <div
          className={classesBuilder(style, {
            switchIcon: true,
            switchIconRight: true,
            ...sizeProps,
          })}
        >
          <Icon name={iconRight} color={!checked ? 'clear' : 'colorDefault'} size={iconSize} />
        </div>
      ) : null}
    </label>
  )
}

export default Toggle
