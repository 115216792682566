import React, { useMemo } from 'react';
import { useModeTheme } from 'components/switchTheme/hooks';
import { MODE_THEME } from 'components/switchTheme/entities';
import { Tooltip } from '../../context';
import { Toggle } from '../index';

const SwitchTheme: React.FC = () => {
  const {
    handlerTheme,
    modeTheme
  } = useModeTheme();

  const title = useMemo(() => {
    return modeTheme === MODE_THEME.DARK
      ? 'Alterar para modo claro'
      : 'Alterar para modo escuro'
  }, [modeTheme])

  return (
    <Tooltip title={ title } position='bottom'>
      <Toggle
        small
        checked={ modeTheme === MODE_THEME.DARK }
        handleChange={
          () => handlerTheme(
            modeTheme === MODE_THEME.DARK
              ? MODE_THEME.LIGHT
              : MODE_THEME.DARK
          )
        }
        iconLeft='sun-outline'
        iconRight='moon-outline'
      />
    </Tooltip>
  )
}

export default SwitchTheme
