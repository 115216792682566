import { useEffect, useState } from 'react';
import { DEFAULT_SORT_BUTTON_ARIA_LABELS, DEFAULT_SORT_BUTTON_TITLES, SORT_DIRECTION } from '../constants/sort';
import { DataTableColumn } from '../types/dataTableColumn.types';
import { UseSort, UseSortProps } from '../types/dataTableSort.types';


/**
 *
 * Custom hook to manage sorting functionality for a data table.
 *
 * @name useSort
 * @hook
 */
const useSort = ({
  sortButtonTitle,
  sortButtonAriaLabel,
  data
}: UseSortProps): UseSort => {

  /**
   * Stores the data to be displayed when rendering the DataTable component.
   */
  const [ dataToRendering, setDataToRendering ] = useState<any[]>(data ?? []);
  /**
   * Stores the current ordering, used to manage the next sort requested.
   */
  const [ ordering, setOrdering ] = useState<SORT_DIRECTION>(SORT_DIRECTION.NONE);
  /**
   * Stores the current column of the table with active sort.
   */
  const [ column, setColumn ] = useState<DataTableColumn>();

  /**
   * Retrieves the current sorting direction for the given column.
   *
   * @param {DataTableColumn} currColumn - The column for which to determine the current sorting direction.
   * @returns {SORT_DIRECTION} The current sorting direction for the specified column.
   */
  const getCurrentOrdering = (currColumn: DataTableColumn): SORT_DIRECTION => {
    return column?.path === currColumn.path ? ordering : SORT_DIRECTION.NONE;
  };

  /**
   * Retrieves the accessible name for the given data table column.
   *
   * @param {DataTableColumn} column - The data table column for which to retrieve the accessible name.
   * @returns {string} The accessible name for the specified data table column.
   */
  const getColumnAccessibleName = (column: DataTableColumn): string => {
    if (typeof column.name !== 'string') {
      return column?.accessibleName ?? ''
    }
    return column.name;
  };

  /**
   * Retrieves the current title for the sort button associated with the given data table column.
   *
   * @param {DataTableColumn} column - The data table column for which to retrieve the sort button title.
   * @returns {string} The title for the sort button associated with the specified data table column.
   */
  const getCurrentTitle = (column: DataTableColumn): string => {
    const currOrderingColumn = getCurrentOrdering(column);

    if (sortButtonTitle?.[currOrderingColumn]) {
      return sortButtonTitle[currOrderingColumn](getColumnAccessibleName(column));
    }
    return DEFAULT_SORT_BUTTON_TITLES[currOrderingColumn](getColumnAccessibleName(column));
  };

  /**
   * Retrieves the current ARIA label for the sort button associated with the given data table column.
   *
   * @param {DataTableColumn} column - The data table column for which to retrieve the ARIA label.
   * @returns {string} The ARIA label for the sort button associated with the specified data table column.
   */
  const getCurrentAriaLabel = (column: DataTableColumn): string => {
    const currOrderingColumn = getCurrentOrdering(column);
    if (sortButtonAriaLabel?.[currOrderingColumn]) {
      return sortButtonAriaLabel[currOrderingColumn](getColumnAccessibleName(column));
    }
    return DEFAULT_SORT_BUTTON_ARIA_LABELS[currOrderingColumn](getColumnAccessibleName(column));
  };

  /**
   * Sorts an array of data in either ascending or descending order based on a specified property path.
   *
   * @param {any[]} data - The array of data to be sorted.
   * @param {string} path - The property path to be used for sorting.
   * @param {SORT_DIRECTION} direction - The direction of sorting, either 'asc' for ascending or 'desc' for descending.
   * @returns {any[]} A new array containing the sorted data based on the specified property and direction.
   */
  const sortData = (data: any[], path: string, direction: SORT_DIRECTION): any[] => {
    return data.slice().sort((itemA, itemB) => {
      const initialValue = direction === SORT_DIRECTION.DESC ? itemB[path] : itemA[path];
      const compareValue = direction === SORT_DIRECTION.DESC ? itemA[path] : itemB[path];

      if (typeof initialValue === 'number' && typeof compareValue === 'number') {
        return initialValue - compareValue;
      } else {
        return String(initialValue).localeCompare(String(compareValue));
      }
    });
  }

  /**
   * Handles the sorting request for the data table.
   *
   * @param {DataTableColumn} requestedColumn - The column for which sorting is requested.
   * @returns {void}
   */
  const onSortRequest = (requestedColumn: DataTableColumn): void => {
    const nextOrderingLiteral = {
      [SORT_DIRECTION.NONE]: SORT_DIRECTION.ASC,
      [SORT_DIRECTION.ASC]: SORT_DIRECTION.DESC,
      [SORT_DIRECTION.DESC]: SORT_DIRECTION.NONE
    };

    let currentColumn = column;
    let requestedOrdering;
    let sortedData;
    const originalData = data || [];

    if (currentColumn?.path !== requestedColumn.path) {
      currentColumn = requestedColumn;
      requestedOrdering = SORT_DIRECTION.ASC;
    } else {
      requestedOrdering = nextOrderingLiteral[ordering];
    }

    if (currentColumn.onSort) {
      setColumn(currentColumn);
      setOrdering(requestedOrdering);
      currentColumn.onSort(currentColumn.path, requestedOrdering);
      return;
    }

    if (requestedOrdering === SORT_DIRECTION.NONE) {
      sortedData = originalData;
    } else {
      sortedData = sortData(originalData, currentColumn.path, requestedOrdering);
    }

    setColumn(currentColumn);
    setOrdering(requestedOrdering);
    setDataToRendering(sortedData || []);
  };

  useEffect(() => {
    setDataToRendering(data ?? [])
  }, [data])

  /**
   * Hook to manage sorting functionality for a data table.
   *
   * @returns {Object} UseSort
   * @property {Array} dataToRendering - State with sorted data to render.
   * @property {function} getCurrentTitle - Gets the title for the current column's sort button.
   * @property {function} getCurrentAriaLabel - Gets the aria-label for the current column's sort button.
   * @property {function} getCurrentOrdering - Gets the current sorting direction for a column.
   * @property {function} onSortRequest - Handles a request to sort the data based on a column.
   */
  return {
    dataToRendering,
    getCurrentTitle,
    getCurrentAriaLabel,
    getCurrentOrdering,
    onSortRequest
  }
}

export default useSort;
