import React from 'react'
import classesBuilder from 'shared/utils/classesBuilder'
import ScrollbarType from '../../types/scrollbar.type'
import UseScrollbar from './hooks/useScrollbar'
import * as style from 'theme/context/scrollbar/platform/mobile/scrollbar.module.scss'

const Scrollbar: React.FC<ScrollbarType> = ({
  maxHeight,
  scrollHeight,
  onlyWheel,
  onScroll,
  children,
  noPadding,
}) => {
  const useRefScrollContent = UseScrollbar(onScroll)

  return (
    <div className={classesBuilder(style, { scrollbarContext: true })}>
      <div
        ref={useRefScrollContent}
        className={classesBuilder(style, { content: true, onlyWheel, noPadding })}
        style={{ maxHeight: maxHeight || '100vh' }}
      >
        <div className={classesBuilder(style, { children: true })} style={{ height: scrollHeight }}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Scrollbar
