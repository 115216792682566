import { capitalize } from 'lodash'
import React from 'react'
import classesBuilder from '../../shared/utils/classesBuilder'
import * as style from './style/tooltip.module.scss'
import { TooltipProps } from './types/tooltip.type'

const Tooltip: React.FC<TooltipProps> = ({ children, title, align, position }) => (
  <div
    className={classesBuilder(style, {
      tooltip: true,
      [`align${capitalize(align)}`]: align,
      [`position${capitalize(position)}`]: position,
    })}
  >
    {title && (
      <div className={classesBuilder(style, { baseTitle: true })}>
        <div className={classesBuilder(style, { limitTitle: true })}>
          <div className={classesBuilder(style, { title: true })}>{title}</div>
        </div>
      </div>
    )}
    {children}
  </div>
)

export default Tooltip
