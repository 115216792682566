import React, { ReactNode } from 'react'
import { ModalPage, PushPage, RouterWapper, SetPage } from 'shared/router'

import BackDrop from 'shared/components/backdrop'
import Button from 'shared/components/button'
import Container from 'shared/components/context/container'
import Content from 'shared/components/context/content'
import Main from 'shared/components/context/main'
import SliderSwiper from 'shared/components/context/sliderSwiper'
import DayNight from 'shared/components/dayNight'
import Menu from 'shared/components/menu'
import MenuTrigger from 'shared/components/menuTrigger'
import Text from 'shared/components/text'
import classesBuilder from 'shared/utils/classesBuilder'

import routesMock from 'shared/components/menu/menuRouter.mock'

import UsePage from './hooks/usePage'

import StructurePageType from './types/page.type'

import { MenuRouteType } from 'shared/components/menu/types/menu.type'
import * as style from 'theme/modules/structure/page/page.module.scss'

const StructurePage: React.FC<StructurePageType> = ({
  children,
  routesGroups,
  menuComponent,
  options = {
    description: {
      portal: 'Portal da Vitta',
      general: `${new Date().getFullYear()} © Vitta Coordenação em Saúde`,
      version: 'v0.0.0',
    },
  },
  translucent,
}) => {
  const {
    isMobile,
    useIsHovered,
    useIsMenuExpand,
    useIsMenuSlider,
    useIsMenuSliderExpand,
    useHeightContent,
    useMaxHeightMenu,
    useRefSliderIntance,
    useRoutesMenu,
    getRefSidebarWrapper,
    onButtonTrigger,
    onMouseTrigger,
    onSliding,
  } = UsePage(routesGroups)

  const classNamesStructure = {
    structurePage: true,
    mobile: isMobile,
    menuExpand: useIsMenuExpand,
    menuHovered: useIsHovered,
  }

  const { description } = options

  const menuMobileTrigger = (): void => {
    const trigger = useIsMenuSliderExpand ? 'close' : 'open'
    useRefSliderIntance.current[trigger]()
  }

  const renderMenu = (): ReactNode => {
    if (menuComponent) {
      return menuComponent({
        callback: useRefSliderIntance.current.close,
        expand: useIsMenuExpand || useIsHovered,
        maxHeight: useMaxHeightMenu,
        routes: useRoutesMenu as unknown as MenuRouteType[] || null
      });
    }

    return (
      <Menu
        callback={useRefSliderIntance.current.close}
        routes={useRoutesMenu || routesMock}
        expand={useIsMenuExpand || useIsHovered}
        maxHeight={useMaxHeightMenu}
      />
    );
  };

  return (
    <RouterWapper>
      <Main translucent={translucent} fullHeight>
        <div data-cascade className={classesBuilder(style, classNamesStructure)}>
          <div data-cascade-scale>
            {isMobile ? (
              <div className={classesBuilder(style, { header: true, hide: useIsMenuSlider })}>
                <MenuTrigger onClick={menuMobileTrigger} />
                <div className={classesBuilder(style, { logo: true })} />
              </div>
            ) : null}
            <div className={classesBuilder(style, { sidebar: true })} onMouseLeave={() => onMouseTrigger(false)}>
              <SliderSwiper
                axisX="left"
                closed
                parentMove
                onSliding={onSliding}
                instanceEmitter={useRefSliderIntance.current}
              >
                {!isMobile ? (
                  <div className={classesBuilder(style, { menuTrigger: true })}>
                    <Button
                      icon="chevron-left-outline"
                      solo
                      rounded
                      size="larger"
                      onClick={onButtonTrigger}
                    />
                  </div>
                ) : null}
                <div
                  ref={getRefSidebarWrapper}
                  className={classesBuilder(style, { sidebarWrapper: true })}
                  onMouseEnter={() => onMouseTrigger(true)}
                  style={{ height: useHeightContent }}
                >
                  <div className={classesBuilder(style, { logo: true })} />
                  { renderMenu() }
                  <div className={classesBuilder(style, { themeMode: true })}>
                    <DayNight />
                  </div>
                  <div className={classesBuilder(style, { infoFooter: true })}>
                    <div className={classesBuilder(style, { question: true })}>
                      <Button
                        icon="question-mark-circle-outline"
                        solo
                        color="colorDefault"
                        size="larger"
                      />
                    </div>
                    <div className={classesBuilder(style, { text: true })}>
                      <Text
                        value={description?.portal}
                        color="light"
                        size="minor"
                        textAlign="center"
                        title
                      />
                      <Text
                        value={description?.general}
                        color="light"
                        size="minor"
                        textAlign="center"
                      />
                      <Text
                        value={description?.version}
                        color="light"
                        size="minor"
                        textAlign="center"
                      />
                    </div>
                  </div>
                </div>
              </SliderSwiper>
              {isMobile ? <div className={classesBuilder(style, { startSwipe: true })} /> : null}
            </div>
            <div className={classesBuilder(style, { pageWrapper: true })}>
              {isMobile && useIsMenuSlider ? (
                <BackDrop
                  show={useIsMenuSliderExpand}
                  onClose={useRefSliderIntance.current.close}
                />
              ) : null}
              <Container>
                <Content height={useHeightContent}>
                  {routesGroups?.length ? (
                    <div className={classesBuilder(style, { pages: true })}>
                      <div>
                        <PushPage routesGroups={routesGroups} />
                      </div>

                      <SetPage routesGroups={routesGroups} />
                    </div>
                  ) : (
                    children
                  )}
                </Content>
              </Container>
            </div>
          </div>
        </div>
        {routesGroups?.length ? <ModalPage routesGroups={routesGroups} /> : null}
      </Main>
    </RouterWapper>
  )
}

export default StructurePage
