import { LabelType } from 'components/pagination/types/common.type';
import { TotalCounterProps } from 'components/pagination/types/totalCounter.type';
import Text from 'components/text';
import React from 'react';

const TotalCounter: React.FC<TotalCounterProps> = ({
  total,
  totalLabel,
}) => {
  const totalCounterLabel = (value?: number, label?: LabelType): string => {
    if (label && typeof label === 'function') {
      return label?.(total);
    }
    return `Total: ${value}`;
  }

  return (total ? (<Text size='medium' value={totalCounterLabel(total, totalLabel)} />) : null)
};

export default TotalCounter;
