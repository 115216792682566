import React from 'react'
import { classesBuilder } from 'shared/utils'

import RowType from './types/row.type'

import * as styleModule from 'theme/context/row/row.module.scss'

const Row: React.FC<RowType> = ({ children, style }) => (
  <div className={classesBuilder(styleModule, { row: true })} {...(style ? { style } : null)}>
    {children}
  </div>
)

export default Row
