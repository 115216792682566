import { useEffect, useState } from 'react';
import { MODE_THEME } from '../../entities';

interface UseSwitchTheme {
  modeTheme: MODE_THEME
  handlerTheme: (dataTheme: MODE_THEME) => void
}

const useSwitchTheme = (): UseSwitchTheme => {
  const [ modeTheme, setModeTheme ] = useState<MODE_THEME>(MODE_THEME.LIGHT);

  const handlerTheme = (dataTheme: MODE_THEME): void => {
    document.body.setAttribute('data-theme', dataTheme)
    localStorage.setItem('vkit-theme', dataTheme)
    setModeTheme(dataTheme)
  }

  useEffect(() => {
    const theme = localStorage.getItem('vkit-theme') as MODE_THEME

    if (theme) {
      document.body.setAttribute('data-theme', theme)
      setModeTheme(theme)
    }
  }, [])

  return {
    modeTheme,
    handlerTheme,
  }
}

export default useSwitchTheme
