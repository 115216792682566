import { classesBuilder } from 'shared/utils/index'
import { FieldBaseType } from '../types/fieldBase.type'
import React, { useState } from 'react'

import FieldBase from './base'
import Icon from 'shared/components/icon'
import * as style from 'theme/components/webform/textField.module.scss'
import { InputEnum } from '../../builder/container/field/types/field.type'

type IconPasswordType = { visible: boolean; onClick?: Function }

const IconPassword: React.FC<IconPasswordType> = ({ visible, onClick }) => (
  <div
    className={classesBuilder(style, { iconCustom: true, cursorPointer: true, iconButton: true })}
    onClick={() => onClick?.()}
  >
    {visible && <Icon name="eye-off-2-outline" size={20} />}
    {!visible && <Icon name="eye-outline" size={20} />}
  </div>
)

const TextField: React.FC<FieldBaseType> = (props) => {
  const [isVisible, setVisible] = useState<boolean>(false)

  return (
    <FieldBase
      {...props}
      inputType={isVisible ? InputEnum.text : InputEnum.password}
      contentRight={<IconPassword visible={isVisible} onClick={() => setVisible(!isVisible)} />}
    />
  )
}

export default TextField
