import { useState } from 'react';

interface UseAsideControl {
  isAsideStateOpen: boolean,
  isAsideOpen: boolean,
  isHovering: boolean,
  handleMouseEvent: (event: 'enter' | 'leave') => void
  handleAsideOpen: (value: boolean) => void
}

const useAsideControl = (): UseAsideControl => {

  const [ isAsideStateOpen, setAsideStateOpen ] = useState<boolean>(() => {
    return window.innerWidth > 1366;
  })
  const [ isAsideOpen, setAsideOpen ] = useState<boolean>(isAsideStateOpen);
  const [ isHovering, setHovering ] = useState<boolean>(!isAsideOpen);

  const handleMouseEvent = (event: 'enter' | 'leave'): void => {
    if(isHovering) {
      if (event === 'enter') {
        setAsideStateOpen(true);
        return
      }
      setAsideStateOpen(false);
    }
  }

  const handleAsideOpen = (isOpen: boolean): void => {
    setAsideOpen(isOpen);
    if (isOpen) {
      setHovering(false);
    } else {
      setHovering(true);
    }
  }

  return {
    isAsideStateOpen,
    isAsideOpen,
    isHovering,
    handleMouseEvent,
    handleAsideOpen
  }
}

export default useAsideControl;
