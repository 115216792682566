export const LABEL_FIRST_PAGE = 'Ir para a primeira página';
export const LABEL_PREV_PAGE = 'Ir para a página anterior';
export const LABEL_NEXT_PAGE = 'Ir para a próxima página';
export const LABEL_LAST_PAGE = 'Ir para a última página';

export const LABEL_FIRST_PAGE_DISABLED = 'Já está na primeira página';
export const LABEL_PREV_PAGE_DISABLED = 'Não existem páginas anteriores';
export const LABEL_NEXT_PAGE_DISABLED = 'Não existem páginas seguintes';
export const LABEL_LAST_PAGE_DISABLED = 'Já está na última página';

export const LABEL_CURRENT_PAGE = 'Página atual:';
