import React, { useEffect, useState } from 'react';

import Icon from 'components/icon'

import { LayerType } from '../types/accordion.type'

import classesBuilder from 'shared/utils/classesBuilder'
import UseLayer from './hooks/useLayer'

import * as style from 'theme/components/accordion/accordion.module.scss'

const Layer: React.FC<LayerType> = ({
  body,
  full,
  title,
  height,
  expanded,
  handleChange,
  accordionItem,
  disabled,
  children,
  cellSpacing,
  handleExpanded,
  overflowVisibleWhenOpen,
}) => {
  const [ overflowVisible, setOverflowVisible ] = useState(false)
  const { getAccordionContent, useAccordion, useAccordionHeight, setAccordion } = UseLayer(
    !!expanded,
  )

  const handleAccordionChange = (): void => {
    setAccordion(!useAccordion)
    handleChange?.(accordionItem)
    handleExpanded?.(!useAccordion)
  }

  const classNames = {
    layer: true,
    full,
    open: useAccordion,
    disabled,
    cellSpacing,
    overflowVisible,
  }

  const accordionElement = body || children

  let timeout = 0;
  useEffect(() => {
    if (!overflowVisibleWhenOpen) {
      return;
    }

    if (!useAccordion) {
      setOverflowVisible(false);
      return;
    }

    window.clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timeout = window.setTimeout(() => {
      setOverflowVisible(true)
    }, 250);
  }, [useAccordion]);

  return (
    <div className={classesBuilder(style, classNames)}>
      <div className={classesBuilder(style, { title: true })} {...(!disabled && { onClick: () => handleAccordionChange() })}>
        <div className={classesBuilder(style, { label: true })}>{title}</div>
        <span className={classesBuilder(style, { icon: true })}>
          <Icon name="arrow-ios-downward-outline" />
        </span>
      </div>

      <div
        style={{ maxHeight: `${useAccordionHeight}px` }}
        className={classesBuilder(style, { body: true })}
        ref={getAccordionContent}
      >
        <div style={{ maxHeight: `${height}px` }} className={classesBuilder(style, { content: true })}>
          {accordionElement}
        </div>
      </div>
    </div>
  )
}

export default Layer
