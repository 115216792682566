import { DataTableEmpty } from 'components/dataTable/types/dataTableEmpty.types';
import EmptyState from 'components/emptyState';
import React from 'react';

/**
 * Represents an empty state for the body of a DataTable when there is no data to display.
 *
 * @interface DataTableBodyEmptyProps
 * @extends DataTableEmpty
 */
interface DataTableBodyEmptyProps extends DataTableEmpty {
  /**
   * The number of columns in the DataTable.
   */
  numColumns: number;
}

/**
 * A React component responsible for rendering the empty state in the body of a DataTable.
 *
 * @component
 * @name DataTableBodyEmpty
 *
 * @param {DataTableBodyEmptyProps} props - The props for the component.
 * @returns {React.ReactElement} A React element representing the DataTableBodyEmpty component.
 */
const DataTableBodyEmpty: React.FC<DataTableBodyEmptyProps> = ({
  numColumns,
  emptyImageURL,
  emptyTitle,
  emptyDescription,
  emptyVertical
}) => (
  <tbody>
    <tr>
      <td colSpan={numColumns}>
        <EmptyState
          imageURL={emptyImageURL}
          title={emptyTitle}
          description={emptyDescription}
          vertical={emptyVertical}
        />
      </td>
    </tr>
  </tbody>
);

export default DataTableBodyEmpty;
