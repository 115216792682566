import React from 'react';

import { Grid, Row } from 'context';

import Navigation from './components/navigation/navigation';
import SelectPageSize from './components/pageSize/pageSize';
import TotalCounter from './components/totalCounter/totalCounter';
import { PaginationProps } from './types/pagination.types';

const Pagination: React.FC<PaginationProps> = ({
  showSelectPageSize = true,
  showTotalCounter = true,
  showNavigation = true,

  pageSizeLabel,
  pageSize,
  pageSizeOptions,
  pageSizeOptionsLabel,
  pageSizeDisabled,
  onChangePageSize,

  total,
  totalLabel,

  page,
  totalPages,
  showFirstAndLastPageActions,
  labelFirstPage,
  labelPrevPage,
  labelNextPage,
  labelLastPage,
  labelFirstPageDisabled,
  labelPrevPageDisabled,
  labelNextPageDisabled,
  labelLastPageDisabled,
  labelCurrentPage,
  onChangePage
}) => {
  return (
    <Row style={{ padding: 0 }}>
      <Grid alignContent='justify' alignItems='center'>
        { showSelectPageSize && (
          <SelectPageSize
            pageSizeLabel={pageSizeLabel}
            pageSize={pageSize}
            pageSizeOptions={pageSizeOptions}
            pageSizeOptionsLabel={pageSizeOptionsLabel}
            pageSizeDisabled={pageSizeDisabled}
            onChangePageSize={onChangePageSize}
          />
        )}
        { showTotalCounter && (
          <TotalCounter
            total={total}
            totalLabel={totalLabel}
          />
        )}
        { showNavigation && (
          <Navigation
            page={page}
            totalPages={totalPages}
            showFirstAndLastPageActions={showFirstAndLastPageActions}
            labelFirstPage={labelFirstPage}
            labelPrevPage={labelPrevPage}
            labelNextPage={labelNextPage}
            labelLastPage={labelLastPage}
            labelFirstPageDisabled={labelFirstPageDisabled}
            labelPrevPageDisabled={labelPrevPageDisabled}
            labelNextPageDisabled={labelNextPageDisabled}
            labelLastPageDisabled={labelLastPageDisabled}
            labelCurrentPage={labelCurrentPage}
            onChangePage={onChangePage}
          />
        )}
      </Grid>
    </Row>
  )
}

export default Pagination;
