import React from 'react'
import { classesBuilder } from 'shared/utils'
import { Text } from '..'
import { Grid } from '../../context'
import emptyImageURL from './assets/empty.png'
import * as style from './style/emptyMessage.module.scss'
import { EmptyStateProps } from './types/emptyState.type'

/**
 *
 * Represents a empty State
 *
 * @name EmptyState
 * @component
 *
 */
const EmptyState:React.FC<EmptyStateProps> = (
  {
    imageURL = emptyImageURL,
    title = 'Nada por aqui!',
    description = 'Nenhum registro encontrado.',
    vertical = false,
  }
) => (
  <div
    className={classesBuilder(style, {
      emptyMessage: true,
      vertical,
    })}
  >
    <div className={style.content}>
      <div
        className={style.image}
        style={{ backgroundImage: `url(${imageURL})` }}
      />
      {
        vertical ? (
          <>
            {
              title && (
                <Text
                  color='colorDefault'
                  padding='16px 0 0'
                  textAlign='center'
                  title
                  value={title}
                />
              )
            }

            {
              description && (
                <Text
                  padding='16px 0 0'
                  size='larger'
                  textAlign='center'
                  value={description}
                />
              )
            }
          </>
        ):(
          <>
            <Grid growing alignItems='center'>
              <div>
                {
                  title && (
                    <Text
                      color='colorDefault'
                      title
                      value={title}
                      padding='0 0 0 30px'
                    />
                  )
                }

                {
                  description && (
                    <Text
                      padding='8px 0 0 30px'
                      size='larger'
                      value={description}
                    />
                  )
                }
              </div>
            </Grid>
          </>
        )
      }
    </div>
  </div>
)

export default EmptyState
