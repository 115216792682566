import React from 'react'

import classesBuilder from 'shared/utils/classesBuilder'

import MenuTriggerType from './types/menutrigger.type'

import * as style from 'theme/components/menu/menutrigger.module.scss'

const MenuTrigger: React.FC<MenuTriggerType> = ({ collapsed, onClick }) => (
  <div className={classesBuilder(style, { menuTrigger: true })} onClick={onClick}>
    <div
      className={classesBuilder(style, {
        icon: true,
        collapsed,
      })}
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
)

export default MenuTrigger
