import React from 'react';

import Button from 'shared/components/button';
import Text from 'shared/components/text';

import { AsideFooterType } from '../../types/asideFooter.type';

const AsideFooter: React.FC<AsideFooterType> = ({
  isOpenedAside,
  description,
}) => {
  return (
    <>
      { !isOpenedAside && (
        <div>
          <Button
            icon="question-mark-circle-outline"
            solo
            color="default"
            size="larger"
          />
        </div>
      )}
      { isOpenedAside && (
        <div>
          <Text
            value={description?.portal}
            color="light"
            size="minor"
            textAlign="center"
            title
          />
          <Text
            value={description?.general}
            color="light"
            size="minor"
            textAlign="center"
          />
          <Text
            value={description?.version}
            color="light"
            size="minor"
            textAlign="center"
          />
        </div>
      )}
    </>
  )
};

export default AsideFooter;
