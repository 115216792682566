import { Loader } from 'components'
import { Row } from 'context'
import React, { FocusEvent, useEffect, useState } from 'react'
import { classesBuilder } from 'shared/utils'
import * as style from 'theme/components/webform/textField.module.scss'
import { WrapField } from '../builder/container/field'
import { StatusEnum } from '../builder/enums'
import { EventFieldType } from '../builder/types'
import * as Field from './container'
import TextFieldType from './types/textField.type'

const TextField: React.FC<TextFieldType> = ({
  clearable,
  disabled,
  double,
  header,
  icon,
  iconDir = 'left',
  label,
  large,
  loading,
  mask,
  max,
  maxLength,
  medium,
  min,
  multiline,
  native,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  onKeyPress,
  onKeyUp,
  placeholder,
  required,
  readOnly,
  shadow,
  small,
  status,
  step,
  textHelper,
  textHelperTop,
  type = 'text',
  value,
  pattern,
  inputMode,
  name
}) => {
  const [ttfTextHelper, setTtfTextHelper] = useState<string>()
  const [ttfValue, setTtfValue] = useState<number | string | { min: number; max: number } | null>(
    value || null,
  )
  const [isTtfFocus, setTtfFocus] = useState<boolean>(false)
  const [ttfStatus, setTtfStatus] = useState<StatusEnum>()

  useEffect(() => {
    setTtfTextHelper(textHelper)
    setTtfStatus(status)
    if (value || value === '') setTtfValue(value)
  }, [value, textHelper, status])

  const handles: EventFieldType = {
    onBlur: (event: FocusEvent<HTMLInputElement>): void => {
      setTtfFocus(false)
      onBlur?.(event)
    },
    onChange,
    onFocus: (event: FocusEvent<HTMLInputElement>): void => {
      setTtfFocus(true)
      onFocus?.(event)
    },
    onKeyDown,
    onKeyPress,
    onKeyUp,
  }

  const ttfProps = {
    [ttfStatus as string]: ttfStatus,
    clearable: ttfValue && clearable,
    disabled,
    focus: isTtfFocus,
    icon,
    large,
    medium,
    multiline,
    shadow,
    small,
    status: ttfStatus,
    textField: 'textField',
    textHelperTop,
    [type]: type,
  }

  const ComponentField =
    {
      cep: Field.Cep,
      cnpj: Field.Cnpj,
      cpf: Field.Cpf,
      date: Field.Date,
      number: Field.Number,
      password: Field.Password,
      range: Field.Range,
      text: Field.Text,
    }[type] || Field.Text

  return (
    <WrapField
      className={classesBuilder(style, ttfProps)}
      header={header}
      label={label}
      required={required}
      style={style}
      textHelper={ttfTextHelper}
      textHelperTop={textHelperTop}
    >
      <ComponentField
        clearable={clearable}
        disabled={disabled}
        double={double}
        icon={icon}
        iconDir={iconDir}
        inputMode={inputMode}
        large={large}
        mask={mask}
        max={max}
        maxLength={maxLength}
        medium={medium}
        min={min}
        multiline={multiline}
        native={native}
        pattern={pattern}
        placeholder={placeholder}
        readOnly={readOnly}
        required={required}
        setTtfStatus={setTtfStatus}
        setTtfTextHelper={setTtfTextHelper}
        setTtfValue={setTtfValue}
        small={small}
        status={ttfStatus}
        statusOrigin={status}
        step={step}
        textHelper={ttfTextHelper}
        value={ttfValue}
        name={name}
        {...handles}
        {...(loading && {
          contentRight: (
            <Row
              style={{
                marginRight: 12,
                padding: 0,
                alignSelf: 'center',
              }}
            >
              <Loader color="colorDefault" />
            </Row>
          ),
        })}
      />
    </WrapField>
  )
}

export default TextField
