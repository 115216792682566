import React, { ReactNode, TableHTMLAttributes } from 'react';
import { classesBuilder } from 'shared/utils';

import * as style from '../../style/dataTable.module.scss';

type Unit = `${number}px` | `${number}%` | 'auto';

/**
 * Represents a header cell within a DataTable.
 *
 * @interface DataTableHeadCellProps
 * @extends TableHTMLAttributes<HTMLTableCellElement>
 */
interface DataTableHeadCellProps extends TableHTMLAttributes<HTMLTableCellElement> {
  /**
   * The content to be displayed within the header cell.
   */
  children?: ReactNode;

  /**
   * Specifies whether the header cell should be hidden.
   */
  isHidden?: boolean;

  /**
   * The width of the header cell.
   */
  width?: Unit;

  /**
   * The minimum width of the header cell.
   */
  minWidth?: Unit;

  /**
   * The maximum width of the header cell.
   */
  maxWidth?: Unit;

  /**
   * The alignment of the content within the header cell.
   */
  align?: 'center' | 'left' | 'right';
}

/**
 * A React component responsible for rendering header cells in a DataTable.
 *
 * @component
 * @name DataTableHeadCell
 *
 * @param {DataTableHeadCellProps} props - The props for the component.
 * @returns {React.ReactElement} A React element representing the DataTableHeadCell component.
 */
const DataTableHeadCell: React.FC<DataTableHeadCellProps> = ({
  children,
  isHidden,
  width = 'auto',
  minWidth,
  maxWidth,
  align,

  ...props
}: DataTableHeadCellProps): React.ReactElement => {
  return (
    <th
      {...props}
      className={classesBuilder(style, { 'hidden': isHidden })}
      style={{ width, minWidth, maxWidth, textAlign: align }}
    >
      { children }
    </th>
  );
};

export default DataTableHeadCell;
