import React, { ReactNode, TableHTMLAttributes } from 'react';
import { classesBuilder } from 'shared/utils';
import * as style from '../../style/dataTable.module.scss';

/**
 * Represents a row within a DataTable.
 *
 * @component
 * @name DataTableRow
 *
 * @property {ReactNode} children - The content to be displayed within the row.
 * @property {boolean} isClickableRow - Indicates whether the row should have a clickable behavior.
 *
 * @example
 *
 * <DataTableRow isClickableRow={true}>
 *   <DataTableCell>Item 1</DataTableCell>
 *   <DataTableCell>Value 1</DataTableCell>
 * </DataTableRow>
 *
 */
interface DataTableRowProps extends TableHTMLAttributes<HTMLTableRowElement>{
  children?: ReactNode,
  isClickableRow?: boolean
}

const DataTableRow: React.FC<DataTableRowProps> = ({ children, isClickableRow, ...props }) => {

  return (
    <tr
      {...props}
      className={ classesBuilder(style, { hoverPointer: isClickableRow }) }
    >
      { children }
    </tr>
  )
};

export default DataTableRow;
