import React from 'react'

import Backdrop from 'shared/components/backdrop'
import Content from 'shared/components/context/content'
import SliderSwiper from 'shared/components/context/sliderSwiper/sliderSwiper'
import classesBuilder from 'shared/utils/classesBuilder'

import * as style from 'theme/router//modal.module.scss'
import { WrapComponentRouteModalType } from './types/wrapComponentRoute.type'
import useWrapModal from './hooks/useWrapModal'

const WrapComponentRouteModal: React.FC<WrapComponentRouteModalType> = ({
  children,
  show = false,
  pathParent,
}) => {
  const { getElementHeight, onClose, useHeaderElement, useHeight, useOpen, useShow } =
    useWrapModal(show)

  const mdlProps = {
    modal: true,
    open: useShow,
  }

  const onSlidingLayer = ({ slided }: { slided: number }): void => {
    if (slided) {
      setTimeout(() => onClose(pathParent), 200)
    }
  }

  return useOpen ? (
    <div data-cascade className={classesBuilder(style, mdlProps)}>
      <Backdrop show={useShow} />
      <div
        {...(useShow ? { 'data-cascade-scale': true } : null)}
        ref={getElementHeight}
        className={classesBuilder(style, { modalBox: true })}
      >
        <SliderSwiper
          axisY="bottom"
          onSliding={onSlidingLayer}
          startElement={useHeaderElement}
          parentMove
        >
          <Content height={useHeight}>{children}</Content>
        </SliderSwiper>
      </div>
    </div>
  ) : null
}

export default WrapComponentRouteModal
