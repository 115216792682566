import React from 'react'

import Icon from 'shared/components/icon'
import { classesBuilder } from 'shared/utils'

import BreadcrumbType from './types/breadcrumb.type'

import * as style from 'theme/components/breadcrumb/breadcrumb.module.scss'

const Breadcrumb: React.FC<BreadcrumbType> = ({ historys }) => (
  <div className={classesBuilder(style, { breadcrumb: true })}>
    {historys?.map((v, k) => (
      <a key={k} className={classesBuilder(style, { history: true })} onClick={v?.onClick}>
        <div className={classesBuilder(style, { icon: true })}>
          {!k ? (
            <Icon name="home-outline" size={16} />
          ) : (
            <Icon name="chevron-right-outline" size={24} />
          )}
        </div>
        <span>{v.name}</span>
      </a>
    ))}
  </div>
)

export default Breadcrumb
