import React, { ChangeEvent, useEffect, useState } from 'react'

import RadioType from './types/radio.type'
import classesBuilder from 'shared/utils/classesBuilder'
import * as style from 'theme/components/webform/radio.module.scss'

const Radio: React.FC<RadioType> = ({
  checked,
  disabled,
  error,
  label,
  large,
  medium,
  name,
  onChange,
  small,
  textLeft,
  value,
}) => {
  const [isRdoDisabled, setRdoDisabled] = useState<boolean>(false)
  const [isRdoChecked, setRdoChecked] = useState<boolean>(false)

  useEffect(() => {
    setRdoChecked(!!checked)
    setRdoDisabled(!!disabled)
  }, [disabled, checked])

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => onChange?.(value, event)

  const rdoProps = {
    error,
    large,
    medium,
    small,
    textLeft,
    radio: true
  }

  return (
    <label className={classesBuilder(style, rdoProps)}>
      <input
        checked={isRdoChecked}
        className={classesBuilder(style, { input: true })}
        disabled={isRdoDisabled}
        name={name}
        onChange={handleChange}
        type="radio"
      />

      <div className={classesBuilder(style, { content: true })}>
        <div className={classesBuilder(style, { box: true })} />
        {label && <span className={classesBuilder(style, { label: true })}>{label}</span>}
      </div>
    </label>
  )
}

export default Radio
