import { useNavigate } from 'react-router-dom'

const useHistoryNavegation = (): {
  goBack: Function
  push: Function
} => {
  const navigate = useNavigate()

  return {
    goBack: () => navigate(-1),
    push: (to: string, transition?: string) =>
      navigate(to, {
        state: {
          transitionName: transition,
        },
      }),
  }
}

export default useHistoryNavegation
