import { NavigationProps } from 'components/pagination/types/navigation.type';
import Row from 'context/row';
import Tooltip from 'context/tooltip/tooltip';
import React from 'react';
import Button from 'shared/components/button';
import { Color } from 'shared/types';
import { classesBuilder } from 'shared/utils'
import useNavigation from '../../hooks/useNavigation';
import * as style from '../../style/pagination.module.scss';

const Navigation: React.FC<NavigationProps> = ({
  showFirstAndLastPageActions = true,
  ...props
}) => {
  const {
    isDisabledFirstPage,
    getLabelFirstPage,
    handleChangeFirstPage,
    isDisabledPrevPage,
    getLabelPrevtPage,
    handleChangePrevPage,
    isDisabledNextPage,
    getLabelNextPage,
    handleChangeNextPage,
    isDisabledLastPage,
    getLabelLastPage,
    handleChangeLastPage,
    getCurrentLabel,
  } = useNavigation(props);

  const getIconDisabledColor = (isDisabled: boolean): Color => {
    return isDisabled ? 'light' : 'colorDefault';
  }

  return (
    <nav aria-label="Pagination">
      <ul className={classesBuilder(style, { paginationNav: true })}>
        { showFirstAndLastPageActions && (
          <li>
            <Tooltip title={getLabelFirstPage()}>
              <Button
                testId='first-page'
                icon='arrowhead-left-outline'
                iconColor={getIconDisabledColor(isDisabledFirstPage())}
                solo
                outlined
                title={getLabelFirstPage()}
                onClick={handleChangeFirstPage}
                size='xLarger'
                disabled={ isDisabledFirstPage() }
              />
            </Tooltip>
          </li>
        )}
        <li>
          <Tooltip title={getLabelPrevtPage()}>
            <Button
              testId='prev-page'
              iconColor={getIconDisabledColor(isDisabledPrevPage())}
              icon='chevron-left-outline'
              solo
              outlined
              title={getLabelPrevtPage()}
              onClick={handleChangePrevPage}
              size='xLarger'
              disabled={ isDisabledPrevPage() }
            />
          </Tooltip>
        </li>
        <li>
          <Tooltip title={getCurrentLabel()}>
            <Button
              testId='current-page'
              solo
              title={getCurrentLabel()}
              disabled
            >
              <Row style={{ padding: '4px 8px' }}>
                { props.page }
              </Row>
            </Button>
          </Tooltip>
        </li>
        <li>
          <Tooltip title={getLabelNextPage()}>
            <Button
              testId='next-page'
              icon='chevron-right-outline'
              iconColor={getIconDisabledColor(isDisabledNextPage())}
              solo
              outlined
              title={getLabelNextPage()}
              onClick={handleChangeNextPage}
              size='xLarger'
              disabled={ isDisabledNextPage() }
            />
          </Tooltip>
        </li>
        { showFirstAndLastPageActions && (
          <li>
            <Tooltip title={getLabelLastPage()}>
              <Button
                testId='last-page'
                icon='arrowhead-right-outline'
                iconColor={getIconDisabledColor(isDisabledLastPage())}
                solo
                outlined
                title={getLabelLastPage()}
                onClick={handleChangeLastPage}
                size='xLarger'
                disabled={isDisabledLastPage()}
              />
            </Tooltip>
          </li>
        )}
      </ul>
    </nav>
  )
}

export default Navigation
