/**
 * Represents the default breakpoints used in the library.
 *
 * @name BREAKPOINTS_DEFAULT
 * @enum
 */
export enum BREAKPOINTS_DEFAULT {
  /**
   * Extra small breakpoint.
   *
   * @example '480px'
   */
  xs = '480px',

  /**
   * Small breakpoint.
   *
   * @example '768px'
   */
  sm = '768px',

  /**
   * Medium breakpoint.
   *
   * @example '1024px'
   */
  md = '1024px',

  /**
   * Large breakpoint.
   *
   * @example '1280px'
   */
  lg = '1280px',

  /**
   * Extra large breakpoint.
   *
   * @example '1536px'
   */
  xl = '1536px',
}
