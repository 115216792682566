import React, { useState } from 'react';

import classesBuilder from 'shared/utils/classesBuilder';

import Button from 'shared/components/button';

import * as style from 'theme/modules/structure/layout.module.scss';

import AsideFooter from './components/asideFooter/asideFooter';
import useAsideControl from './hooks/useAsideControl/useAsideControl';
import { AsideFooterDescriptionType } from './types/asideFooter.type';
import { LayoutProps } from './types/layout.type';

const Layout: React.FC<LayoutProps> = ({
  header,
  asideContent,
  asideFooter,
  children
}) => {

  const {
    isAsideStateOpen,
    isAsideOpen,
    isHovering,
    handleMouseEvent,
    handleAsideOpen
  } = useAsideControl();

  const [ asideFooterDescription ] = useState<AsideFooterDescriptionType>(
    asideFooter?.description ?? {
    portal: 'Portal da Vitta',
    general: `${new Date().getFullYear()} © Vitta Coordenação em Saúde`,
    version: 'v0.0.0',
  })

  return (
    <main
      className={ classesBuilder(style, {
        layout: true,
        isHoveringAside: isHovering,
        isFixedOpenAside: isAsideOpen
      }) }
    >
      <aside
        className={ classesBuilder(style, {
          aside: true,
          isOpenAside: isAsideStateOpen
        }) }
        onMouseEnter={ () => handleMouseEvent('enter') }
        onMouseLeave={ () => handleMouseEvent('leave') }
      >
        <div className={ classesBuilder(style, { asideButtonTrigger: true }) }>
          <Button
            icon="chevron-left-outline"
            solo
            rounded
            size="larger"
            onClick={() => handleAsideOpen(!isAsideOpen) }
          />
        </div>
        <header className={ classesBuilder(style, { asideHeader: true }) }>
          <div className={ classesBuilder(style, { logo: true }) } />
        </header>
        <section className={ classesBuilder(style, { asideContent: true }) }>
          { asideContent?.(isAsideStateOpen) }
        </section>
        { asideFooter && (
          <footer className={ classesBuilder(style, { asideFooter: true }) }>
            <AsideFooter
              isOpenedAside={ isAsideStateOpen }
              description={ asideFooterDescription }
            />
          </footer>
        )}
      </aside>

      <section className={ classesBuilder(style, {
          layoutMain: true,
        }) }>
        { header && (
          <header className={ classesBuilder(style, {
            layoutMainHeader: true,
          })}>
            <div className={ classesBuilder(style, { layoutMainHeaderContainer: true }) }>
              { header }
            </div>
          </header>
        )}
        <div className={ classesBuilder(style, { layoutMainContent: true }) }>
          <div className={ classesBuilder(style, { container: true }) } >
            { children }
          </div>
        </div>
      </section>
    </main>
  )
};

export default Layout;
