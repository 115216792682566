import React, { ChangeEvent, FocusEvent, useCallback, useEffect, useRef, useState } from 'react'
import { FieldBaseType, TagNameEnum } from '../types/fieldBase.type'

import * as style from 'theme/components/webform/textField.module.scss'
import { BaseField } from '../../builder/container/field'
import { InputEnum } from '../../builder/container/field/types/field.type'
import { EventFieldType } from '../../builder/types'
import { createValidation } from '../../builder/validates'

const FieldBase: React.FC<FieldBaseType> = ({
  clearable,
  contentRight,
  disabled,
  handleBeforeChange,
  maxLength,
  icon,
  iconDir = 'left',
  inputType = 'text',
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  onKeyPress,
  onKeyUp,
  placeholder,
  readOnly,
  required,
  setTtfTextHelper,
  setTtfStatus,
  setTtfValue,
  textHelper,
  status,
  statusOrigin,
  tagName: TagName = 'input',
  type = 'text',
  value,
  pattern,
  inputMode,
  max,
  min,
  name,
}) => {
  const [isTtfValidated, setTtfValidated] = useState<boolean>(false)
  const inputTypeRef = useRef<string>()

  const validateTextField = createValidation({
    setHelperState: setTtfTextHelper,
    setStatusState: setTtfStatus,
    setValidatedState: setTtfValidated,
    status: statusOrigin,
    textHelper,
    type,
    required,
  })

  const treatValue = useCallback(
    (newValue?: string, isValidated = false): string => {
      const text: string = handleBeforeChange?.(newValue) ?? newValue
      if (isValidated) validateTextField(text)
      setTtfValue(text)
      return text
    },
    [handleBeforeChange, validateTextField, setTtfValue],
  )

  const retreatValue = useCallback(() => treatValue(value as string, true), [value, treatValue])

  const handles: EventFieldType = {
    onBlur: (event: FocusEvent<HTMLInputElement>): void => {
      validateTextField(event.target.value)
      onBlur?.(event.target.value, event)
    },
    onChange: (event: ChangeEvent<HTMLInputElement>): void => {
      const text = treatValue(event.target.value, isTtfValidated)
      onChange?.(text, event)
    },
    onFocus,
    onKeyDown,
    onKeyPress,
    onKeyUp,
  }

  useEffect(() => {
    if (type !== inputTypeRef.current) {
      retreatValue()
      inputTypeRef.current = type
    }
  }, [type, retreatValue])

  return (
    <BaseField
      iconDir={iconDir}
      clearable={clearable}
      contentRight={contentRight}
      disabled={disabled}
      icon={icon}
      inputType={inputType as InputEnum}
      maxLength={maxLength}
      placeholder={placeholder}
      readOnly={readOnly}
      setValueState={setTtfValue}
      status={status}
      style={style}
      tagName={TagName as TagNameEnum}
      value={value}
      pattern={pattern}
      inputMode={inputMode}
      max={max}
      min={min}
      name={name}
      onClear={() => {
        const text = treatValue('', isTtfValidated)
        onChange?.(text, {})
      }}
      {...handles}
    />
  )
}

export default FieldBase
