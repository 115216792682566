/**
 *
 * Represents a cell within a DataTable.
 *
 */
import React, { ReactNode, TableHTMLAttributes } from 'react';
import { classesBuilder } from 'shared/utils';
import * as style from '../../style/dataTable.module.scss';

/**
 * Props for the DataTableCell component.
 */
export interface DataTableCellProps extends TableHTMLAttributes<HTMLTableCellElement> {
  /**
   * The content to be displayed within the cell.
   */
  children?: string | ReactNode | number;

  /**
   * Determines whether the cell should be hidden.
   */
  isHidden?: boolean;

  /**
   * A function to be executed when the cell is clicked.
   */
  onClick?: () => void;

  /**
   * The alignment of the cell's content.
   */
  align?: 'center' | 'left' | 'right';
}

/**
 * A cell component to be used within a DataTable.
 *
 * @component
 * @name DataTableCell
 *
 * @param {DataTableCellProps} props - The props for the DataTableCell component.
 * @returns {JSX.Element} A DataTable cell.
 */
const DataTableCell: React.FC<DataTableCellProps> = ({
  children,
  isHidden,
  onClick,
  align,

  className,

  ...props
}) => {

  const mergedProps = [
    className,
    classesBuilder(style, { 'hidden': isHidden })
  ];

  return (
    <td
      {...props}
      className={mergedProps.join(' ')}
      onClick={onClick}
      style={{ textAlign: align }}
    >
      { children }
    </td>
  );
};

export default DataTableCell;
