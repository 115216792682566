import React from 'react'

import { AccordionType } from './types/accordion.type'

import UseAccordion from './hooks/useAccordion'

import Layer from './container/layer'

import * as style from 'theme/components/accordion/accordion.module.scss'
import classesBuilder from '../../shared/utils/classesBuilder'

const Accordion: React.FC<AccordionType> = ({ ...props }) => {
  const { handleChange, useAccordions } = UseAccordion(props)

  const classNames = {
    accordion: true,
    box: props.box,
    line: props.line,
  }

  return (
    <div className={classesBuilder(style, classNames)}>
      {useAccordions.map((acc, index) => (
        <Layer
          group={!!props.group}
          handleChange={handleChange}
          key={index}
          {...acc}
          overflowVisibleWhenOpen={props.overflowVisibleWhenOpen}
        />
      ))}
    </div>
  )
}

export default Accordion
