import { merge } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { BREAKPOINTS_DEFAULT } from '../constants/breakpoints'
import { DataTableBreakpoint, DataTableBreakpointValue, DataTableBreakpoints } from '../types/dataTableBreakpoints.types'
import { DataTableColumn } from '../types/dataTableColumn.types'

type UseBreakpointsProps = {
  columns: DataTableColumn[]
  breakpoints?: DataTableBreakpoints
}

type UseBreakpoints = {
  isHidden: (path: string) => boolean
}

const useBreakpoints = ({ columns, breakpoints }: UseBreakpointsProps): UseBreakpoints => {

  const [ hiddenColumns, setHiddenColumns ] = useState<Record<string, boolean>>();

  const currBreakpoints = merge(BREAKPOINTS_DEFAULT, breakpoints);

  const isBreakpointValue = (value: any): boolean => /^(\d+)px$/.test(value);

  const isBreakpointPlain = (value: any): boolean => {
    const keys = Object.keys(value);
    return keys.every(key => key === 'min' || key === 'max');
  }

  const isBreakpointLimit = (value: DataTableBreakpointValue): boolean => {
    return window.matchMedia(`(max-width: ${value})`).matches;
  };

  const checkRangeBreakpoint = ({ min, max }: DataTableBreakpoint): boolean => {
    return window.matchMedia(`(min-width: ${min}) and (max-width: ${max})`).matches;
  }

  const handleColumnBreakpoint = useCallback((): void => {
    const hiddenControl: Record<string, boolean> = {};

    columns.forEach((column) => {
      if (!hiddenControl[column.path]) {
        hiddenControl[column.path] = false;
      }

      if (column.hide) {
        const valueToCheck = currBreakpoints[column.hide as string] || column.hide;
        if (isBreakpointValue(valueToCheck)) {
          hiddenControl[column.path] = isBreakpointLimit(valueToCheck as DataTableBreakpointValue);
        }
        if (isBreakpointPlain(valueToCheck)) {
          const { min, max } = valueToCheck as DataTableBreakpoint;
          if (min && max) {
            hiddenControl[column.path] = checkRangeBreakpoint({ min, max });
          }
          hiddenControl[column.path] = isBreakpointLimit((min || max) as DataTableBreakpointValue);
        }
      }
    })
    setHiddenColumns(hiddenControl);
  }, [columns, currBreakpoints])

  const isHidden = (path: string): boolean => {
    return hiddenColumns?.[path] || false;
  }

  useEffect(() => {
    if (!hiddenColumns) {
      handleColumnBreakpoint();
    }

    window.addEventListener('resize', handleColumnBreakpoint);

    return () => window.removeEventListener('resize', handleColumnBreakpoint);
  }, [handleColumnBreakpoint, hiddenColumns])

  return {
    isHidden
  }
}

export default useBreakpoints;
