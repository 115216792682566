import Dropdown from 'components/dropdown';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_LABEL, DEFAULT_PAGE_SIZE_LABEL_OPTIONS, DEFAULT_PAGE_SIZE_OPTIONS } from 'components/pagination/constants/pagination';
import { LabelType } from 'components/pagination/types/common.type';
import { SelectPageSizeProps } from 'components/pagination/types/selectPageSize.type';
import React from 'react';

const SelectPageSize: React.FC<SelectPageSizeProps> = ({
  pageSize = DEFAULT_PAGE_SIZE,
  onChangePageSize,
  pageSizeDisabled,
  pageSizeLabel = DEFAULT_PAGE_SIZE_LABEL,
  pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS,
  pageSizeOptionsLabel = DEFAULT_PAGE_SIZE_LABEL_OPTIONS,
}) => {
  const dropdownLabel = (value?: number, label?: LabelType): string => {
    if (label && typeof label === 'function') {
      return label?.(pageSize);
    }
    return `${value} ${label}`;
  }

  const handlePageSizeChange = (value: number): void => {
    if (pageSizeDisabled) {
      return
    }
    onChangePageSize(value)
  }

  return (
    <Dropdown
      disabled={pageSizeDisabled}
      invertColor
      color='colorDefault'
      label={dropdownLabel(pageSize, pageSizeLabel)}
      actionOptions={pageSizeOptions.map((num) => ({
        label: dropdownLabel(num, pageSizeOptionsLabel),
        onClick: () => handlePageSizeChange(num),
        icon: 'hash'
      }))}
    />
  )
};

export default SelectPageSize;
