export default function classesBuilder(styles: { default: Record<string, string> } | Record<string, string>, props: Record<string, string | boolean | number | undefined>): string {
  const classNames = (styles.default || styles) as Record<string, string>;
  const generatedClasses: string[] = []
  const { ...rest } = props

  Object.keys(rest).forEach((key) => {
    const propValue = rest[key]

    if (propValue && classNames[key] && typeof classNames[key] === 'string') {
      generatedClasses.push(classNames[key])
    }
  })

  return generatedClasses.join(' ');
}
