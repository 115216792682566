import React from 'react'

import classesBuilder from 'shared/utils/classesBuilder'

import BoxType from './types/box.type'

import * as style from 'theme/context/box/box.module.scss'

const Box: React.FC<BoxType> = ({ elevation, children, outlined, rounded, type, onClick }) => (
  <div
    className={classesBuilder(style, {
      box: true,
      [`elevation${elevation}`]: elevation,
      outlined,
      rounded,
      [type || '']: type,
      canHover: Boolean(onClick)
    })}
    onClick={ onClick }
  >
    <div className={classesBuilder(style, { wrapper: true })}>{children}</div>
  </div>
)

export default Box
