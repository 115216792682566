import { get } from 'lodash';
import { ReactNode } from 'react';
import { DataTableColumn } from '../types/dataTableColumn.types';

type FormatedData = string | ReactNode | number;

type UseColumnsProps = {
  columns: DataTableColumn[]
}

type UseColumns = {
  countColumns: number,
  getFormatedData: (column: DataTableColumn, value: any, index: number) => FormatedData
  getFilteredColumns: () => DataTableColumn[]
}

const useColumns = ({
  columns
}: UseColumnsProps): UseColumns => {

  const getFormatedData = (column: DataTableColumn, value: any, index: number): FormatedData => {
    if (column.format) {
      return column.format(value, index);
    }
    return get(value, column.path, '-');
  }

  const getFilteredColumns = (): DataTableColumn[]  => {
    return columns.filter((column) => !column.omit);
  }

  return {
    countColumns: columns.length,
    getFormatedData,
    getFilteredColumns
  }
}

export default useColumns;
