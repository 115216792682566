import { SORT_DIRECTION } from 'components/dataTable/constants/sort';
import React, { ReactNode } from 'react';
import { classesBuilder } from 'shared/utils';
import * as style from '../../style/dataTable.module.scss';

interface TableSortActionProps {
  ordering: SORT_DIRECTION
  buttonTitle: string
  buttonAria: string
  onSort: () => void
  isDisabled?: boolean
  sortIcon?: (
    sortDirection: SORT_DIRECTION,
    buttonTitle: string,
    buttonAria: string,
    isDisabled: boolean,
    onSort: Function
  ) => ReactNode;
}

/**
 *
 * Represents a sorting action component for a table column.
 *
 * @component
 * @name TableSortAction
 *
 * @property { Function } sortIcon - The custom icon for sorting
 * @property { SORT_DIRECTION } ordering - The current sorting direction of the column.
 * @property { string } buttonTitle - The title attribute for the sorting action button.
 * @property { string } buttonAria - The aria-label attribute for the sorting action button.
 * @property { Function } onSort - The callback function to be executed when the sorting action button is clicked.
 *
 */
const TableSortAction: React.FC<TableSortActionProps> = ({
  sortIcon,
  ordering,
  onSort,
  buttonTitle,
  buttonAria,
  isDisabled,
}) => {

  const isASCActive = ordering === SORT_DIRECTION.ASC;
  const isDESCActive = ordering === SORT_DIRECTION.DESC;

  const getSortComponent = (): ReactNode => {
    if (sortIcon) {
      return sortIcon(
        ordering,
        buttonTitle ?? '',
        buttonAria ?? '',
        isDisabled ?? false,
        onSort
      );
    }

    return (
      <button
        className={ style.tableSort }
        onClick={ onSort }
        title={ buttonTitle }
        aria-label={ buttonAria }
        disabled={ isDisabled }
        >
        {(ordering === SORT_DIRECTION.NONE || ordering === SORT_DIRECTION.ASC) && (
          <span className={ classesBuilder(style, { 'arrow-up': true, 'active': isASCActive }) } aria-hidden='true' />
        )}
        {(ordering === SORT_DIRECTION.NONE || ordering === SORT_DIRECTION.DESC) && (
          <span className={ classesBuilder(style, { 'arrow-down': true, 'active': isDESCActive }) } aria-hidden='true' />
        )}
      </button>
    )
  }

  return (
    <>{ getSortComponent() }</>
  );
};

export default TableSortAction;
