import { LABEL_CURRENT_PAGE, LABEL_FIRST_PAGE, LABEL_FIRST_PAGE_DISABLED, LABEL_LAST_PAGE, LABEL_LAST_PAGE_DISABLED, LABEL_NEXT_PAGE, LABEL_NEXT_PAGE_DISABLED, LABEL_PREV_PAGE, LABEL_PREV_PAGE_DISABLED } from '../constants/navigation';
import { LabelType } from '../types/common.type';
import { NavigationProps } from '../types/navigation.type';

interface UseNavigation {
  isDisabledFirstPage: () => boolean
  getLabelFirstPage: () => string
  handleChangeFirstPage: () => void
  isDisabledPrevPage: () => boolean
  getLabelPrevtPage: () => string
  handleChangePrevPage: () => void
  isDisabledNextPage: () => boolean
  getLabelNextPage: () => string
  handleChangeNextPage: () => void
  isDisabledLastPage: () => boolean
  getLabelLastPage: () => string
  handleChangeLastPage: () => void
  page: number
  getCurrentLabel: () => string
}

const useNavigation = ({
  page,
  totalPages,
  onChangePage,
  labelFirstPage = LABEL_FIRST_PAGE,
  labelPrevPage = LABEL_PREV_PAGE,
  labelNextPage = LABEL_NEXT_PAGE,
  labelLastPage = LABEL_LAST_PAGE,
  labelFirstPageDisabled = LABEL_FIRST_PAGE_DISABLED,
  labelPrevPageDisabled = LABEL_PREV_PAGE_DISABLED,
  labelNextPageDisabled = LABEL_NEXT_PAGE_DISABLED,
  labelLastPageDisabled = LABEL_LAST_PAGE_DISABLED,
  labelCurrentPage = LABEL_CURRENT_PAGE,
}: NavigationProps): UseNavigation => {

  const getLabel = (label: LabelType): string => {
    if (label && typeof label === 'function') {
      return label?.(page);
    }
    return label;
  }

  const isDisabledFirstPage = (): boolean => {
    return page === 1;
  }

  const getLabelFirstPage = (): string => {
    if (isDisabledFirstPage()) {
      return getLabel(labelFirstPageDisabled)
    }
    return  getLabel(labelFirstPage)
  }

  const handleChangeFirstPage = (): void => {
    if (isDisabledFirstPage()) {
      return;
    }
    onChangePage(1);
  }

  const isDisabledPrevPage = (): boolean => {
    return page === 1;
  }

  const getLabelPrevtPage = (): string => {
    if (isDisabledPrevPage()) {
      return getLabel(labelPrevPageDisabled)
    }
    return  getLabel(labelPrevPage)
  }

  const handleChangePrevPage = (): void => {
    if (isDisabledPrevPage()) {
      return;
    }
    onChangePage(page - 1);
  }

  const isDisabledNextPage = (): boolean => {
    return page >= totalPages;
  }

  const getLabelNextPage = (): string => {
    if (isDisabledNextPage()) {
      return getLabel(labelNextPageDisabled)
    }
    return  getLabel(labelNextPage)
  }

  const handleChangeNextPage = (): void => {
    if (isDisabledNextPage()) {
      return;
    }
    onChangePage(page + 1);
  }

  const isDisabledLastPage = (): boolean => {
    return page === totalPages;
  }

  const getLabelLastPage = (): string => {
    if (isDisabledLastPage()) {
      return getLabel(labelLastPageDisabled)
    }
    return  getLabel(labelLastPage)
  }

  const handleChangeLastPage = (): void => {
    if (isDisabledLastPage()) {
      return;
    }
    onChangePage(totalPages);
  }

  const getCurrentLabel = (): string => {
    return `${ getLabel(labelCurrentPage) } ${page}`
  }

  return {
    isDisabledFirstPage,
    getLabelFirstPage,
    handleChangeFirstPage,
    isDisabledPrevPage,
    getLabelPrevtPage,
    handleChangePrevPage,
    isDisabledNextPage,
    getLabelNextPage,
    handleChangeNextPage,
    isDisabledLastPage,
    getLabelLastPage,
    handleChangeLastPage,
    page,
    getCurrentLabel
  }
}

export default useNavigation;
