import React, { ChangeEvent, useEffect, useState } from 'react'

import classesBuilder from 'shared/utils/classesBuilder'
import * as style from 'theme/components/webform/checkbox.module.scss'
import CheckboxType from './types/checkbox.type'

const Checkbox: React.FC<CheckboxType> = ({
  checked,
  disabled,
  error,
  label,
  large,
  medium,
  noAnimation,
  onChange,
  rounded,
  small,
  textLeft,
  title,
  indeterminate
}) => {
  const [isChbDisabled, setChbDisabled] = useState<boolean>(false)
  const [isChbChecked, setChbChecked] = useState<boolean>(false)
  const [isChbIndeterminate, setChbIndeterminate] = useState<boolean>(false)

  useEffect(() => {
    setChbChecked(!!checked)
    setChbDisabled(!!disabled)
    setChbIndeterminate(!!indeterminate)
  }, [disabled, checked, indeterminate])

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void =>
    onChange?.(!isChbChecked, event)

  const chbProps = {
    checkbox: true,
    error,
    large,
    medium,
    noAnimation,
    rounded,
    small,
    textLeft,
  }

  return (
    <label className={classesBuilder(style, chbProps)} title={title}>
      <input
        type="checkbox"
        disabled={isChbDisabled}
        checked={isChbChecked}
        onChange={handleChange}
        className={classesBuilder(style, { input: true })}
      />

      <div className={classesBuilder(style, { content: true })}>
        <div className={classesBuilder(style, { box: true })}>
          {isChbIndeterminate && <div className={classesBuilder(style, { indeterminate: true })} />}
          <div className={classesBuilder(style, { check: true })} />
        </div>
        {label && <span className={classesBuilder(style, { label: true })}>{label}</span>}
      </div>
    </label>
  )
}

export default Checkbox
