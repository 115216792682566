import { DataTableLoading } from 'components/dataTable/types/dataTableLoading.types';
import Text from 'components/text';
import React, { ReactNode } from 'react';

/**
 * Represents the loading state of the table body in a DataTable component.
 *
 * @interface DataTableBodyLoadingProps
 * @extends DataTableLoading
 */
interface DataTableBodyLoadingProps extends Omit<DataTableLoading, 'isLoading'> {
  /**
   * The number of columns in each loading row.
   */
  countColumns: number;
}

/**
 * A React component responsible for rendering the loading state in the body of a DataTable.
 *
 * @component
 * @name DataTableBodyLoading
 *
 * @param {DataTableBodyLoadingProps} props - The props for the component.
 * @returns {React.ReactElement} A React element representing the DataTableBodyLoading component.
 *
 * @example
 *
 * // Loading Component must receive a <tr><td> to maintain accessibility and semantics
 * const customLoadingComponent = (loadingLinesNumber, countColumns) => {
 *   return (
 *     <tr>
 *       <td colSpan={countColumns}> loading ... </td>
 *     </tr>
 *   );
 * };
 *
 * <DataTable loadingComponent={customLoadingComponent} />
 */
const DataTableBodyLoading: React.FC<DataTableBodyLoadingProps> = ({
  loadingComponent,
  loadingLinesNumber = 5,
  countColumns = 1,
}) => {
  const tableRows = Array.from({ length: loadingLinesNumber }, (_, rowIndex) => {
    const rowColumns = Array.from({ length: countColumns }, (_, columnIndex) => (
      <td key={columnIndex}>
        <Text value="" rounded />
      </td>
    ));
    return <tr key={rowIndex}>{rowColumns}</tr>;
  });

  const getLoadingComponent = (): ReactNode => {
    if (loadingComponent) {
      return loadingComponent(loadingLinesNumber, countColumns);
    }
    return tableRows;
  };

  return (
    <tbody>
      {getLoadingComponent()}
    </tbody>
  );
};

export default DataTableBodyLoading;
