import Pagination from 'components/pagination/pagination';
import { Box } from 'context';
import { isObject } from 'lodash';
import React from 'react';
import { classesBuilder } from 'shared/utils';

import DataTableBodyEmpty from './components/dataTableBodyEmpty/dataTableBodyEmpty';
import DataTableBodyLoading from './components/dataTableBodyLoading/dataTableBodyLoading';
import DataTableCell from './components/dataTableCell/dataTableCell';
import DataTableCellAction from './components/dataTableCellAction/dataTableCellAction';
import DataTableHeadCell from './components/dataTableHeadCell/dataTableHeadCell';
import DataTableRow from './components/dataTableRow/dataTableRow';

import TableSortAction from './components/tableSortAction/tableSortAction';
import useBreakpoints from './hooks/useBreakpoints';
import useColumns from './hooks/useColumns';
import useSort from './hooks/useSort';
import * as style from './style/dataTable.module.scss';
import { DataTableProps } from './types/dataTable.types';

const DataTable: React.FC<DataTableProps<any>> = ({
  columns,
  data,
  uniqueKeyField = 'id',
  breakpoints,

  sortIcon,
  sortButtonTitle,
  sortButtonAriaLabel,

  emptyImageURL,
  emptyTitle,
  emptyDescription,
  emptyVertical,

  isLoading,
  loadingLinesNumber,
  loadingComponent,

  action,

  showPagination = true,
  showSelectPageSize = true,
  showTotalCounter = true,
  showNavigation = true,
  pageSizeLabel,
  pageSize,
  pageSizeOptions,
  pageSizeOptionsLabel,
  pageSizeDisabled,
  onChangePageSize,
  total,
  totalLabel,
  page,
  totalPages,
  showFirstAndLastPageActions,
  labelFirstPage,
  labelPrevPage,
  labelNextPage,
  labelLastPage,
  labelFirstPageDisabled,
  labelPrevPageDisabled,
  labelNextPageDisabled,
  labelLastPageDisabled,
  labelCurrentPage,
  onChangePage,

  onRowClick,
  size = 'small',
  elevation = 4,
  outlined = true,
  rounded = true,
  backGroundColorHide = false,

  ...props
}) => {

  const {
    countColumns,
    getFormatedData,
    getFilteredColumns,
  } = useColumns({ columns });

  const { isHidden } = useBreakpoints({
    columns: getFilteredColumns(),
    breakpoints
  });

  const {
    dataToRendering,
    getCurrentAriaLabel,
    getCurrentTitle,
    getCurrentOrdering,
    onSortRequest
  } = useSort({
    sortButtonTitle,
    sortButtonAriaLabel,
    data
  });

  const isEmpty = dataToRendering.length === 0 && !isLoading;
  const hasAction = action && (isObject(action.items) || Array.isArray(action.items));

  return (
    <Box type="sheet" elevation={elevation} outlined={outlined} rounded={ rounded }>
      <div className={ classesBuilder(style, { tableBox: !backGroundColorHide }) }>
        <table className={ classesBuilder(style, {
          table: true,
          lineHover: !isEmpty && !isLoading,
          [size]: size
        }) } {...props}>
          <thead>
            <DataTableRow>
              {
                getFilteredColumns().map((column) => (
                  <DataTableHeadCell
                    isHidden={ isHidden(column.path) }
                    key={column.path}
                    width={ column.width }
                    minWidth={ column.minWidth }
                    maxWidth={ column.maxWidth }
                  >
                    <>
                      { column.name }
                      { column.sortable && (
                        <TableSortAction
                          sortIcon={ sortIcon }
                          onSort={ () => onSortRequest(column) }
                          ordering={
                            getCurrentOrdering(column) }
                          buttonTitle={
                            getCurrentTitle(column) }
                          buttonAria={
                            getCurrentAriaLabel(column) }
                          isDisabled={ isLoading || isEmpty }
                        />
                      )}
                    </>
                  </DataTableHeadCell>
                ))
              }
              {hasAction && (
                <DataTableHeadCell width='auto'>
                  { action.name }
                </DataTableHeadCell>
              )}
            </DataTableRow>
          </thead>
          { isLoading && (
            <DataTableBodyLoading
              loadingComponent={loadingComponent}
              countColumns={ action ? countColumns + 1 : countColumns }
              loadingLinesNumber={loadingLinesNumber ?? 5}
            />
          )}
          { isEmpty && (
            <DataTableBodyEmpty
              numColumns={ action ? countColumns + 1 : countColumns }
              emptyImageURL={ emptyImageURL }
              emptyTitle={ emptyTitle }
              emptyDescription={ emptyDescription }
              emptyVertical={ emptyVertical }
            />
          )}
          { !!dataToRendering?.length && (
            <tbody>
              {
                dataToRendering?.map((item, index) => {
                  const rowAction = onRowClick?.(item);
                  const hasRowClick = typeof rowAction === 'function';
                  return (
                    <DataTableRow
                      key={item[uniqueKeyField]}
                      isClickableRow={ hasRowClick }
                    >
                      {
                        getFilteredColumns().map((column) => (
                          <DataTableCell
                            onClick={ () => rowAction?.(item) }
                            key={`${column.path}-${item[uniqueKeyField]}`}
                            isHidden={ isHidden(column.path) }
                          >
                            { getFormatedData(column, item, index) }
                          </DataTableCell>
                        ))
                      }
                      {hasAction && (
                        <DataTableCellAction
                          grouped={ action.grouped }
                          vertical={ action.vertical }
                          items={ action.items }
                          data={ item }
                        />
                      )}
                    </DataTableRow>
                  )
                })
              }
            </tbody>
          )}
        </table>
        { showPagination && (
          <footer className={ classesBuilder(style, {
            footer: true,
            [size]: size
          }) }>
            <Pagination
              showSelectPageSize={showSelectPageSize}
              showTotalCounter={showTotalCounter}
              showNavigation={showNavigation}
              pageSizeLabel={pageSizeLabel}
              pageSize={pageSize}
              pageSizeOptions={pageSizeOptions}
              pageSizeOptionsLabel={pageSizeOptionsLabel}
              pageSizeDisabled={pageSizeDisabled}
              onChangePageSize={onChangePageSize}
              total={total}
              totalLabel={totalLabel}
              page={page}
              totalPages={totalPages}
              showFirstAndLastPageActions={showFirstAndLastPageActions}
              labelFirstPage={labelFirstPage}
              labelPrevPage={labelPrevPage}
              labelNextPage={labelNextPage}
              labelLastPage={labelLastPage}
              labelFirstPageDisabled={labelFirstPageDisabled}
              labelPrevPageDisabled={labelPrevPageDisabled}
              labelNextPageDisabled={labelNextPageDisabled}
              labelLastPageDisabled={labelLastPageDisabled}
              labelCurrentPage={labelCurrentPage}
              onChangePage={onChangePage}
            />
          </footer>
        )}
      </div>
    </Box>
  )
};

export default DataTable;
