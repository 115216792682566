import React from 'react'

import Scrollbar from 'shared/components/context/scrollbar'
import { classesBuilder } from 'shared/utils'

import ContentType from './types/content.type'

import * as style from 'theme/context/content/content.module.scss'

const Content: React.FC<ContentType> = ({ height, children }) => (
  <div className={classesBuilder(style, { content: true })} style={{ height }}>
    <Scrollbar maxHeight={height}>{children}</Scrollbar>
  </div>
)

export default Content
