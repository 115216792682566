import React, { useCallback } from 'react'
import Icon from 'shared/components/icon'
import classesBuilder from 'shared/utils/classesBuilder'
import { StatusEnum } from '../../enums'
import { BaseFieldType } from './types/field.type'

type IconStatusType = { status?: StatusEnum; className: string }

const IconStatus: React.FC<IconStatusType> = ({ status, className }) => (
  <div className={className}>
    {status === StatusEnum.success && <Icon name="checkmark-circle-2-outline" size={20} />}
    {status === StatusEnum.error && <Icon name="close-circle-outline" size={20} />}
  </div>
)

const BaseField: React.FC<BaseFieldType> = ({
  autofocus,
  clearable,
  contentRight,
  disabled,
  icon,
  iconDir = 'left',
  inputType = 'text',
  inputMode,
  max,
  maxLength,
  min,
  onBlur,
  onChange,
  onClear,
  onClick,
  onFocus,
  onKeyDown,
  onKeyPress,
  onKeyUp,
  pattern,
  placeholder,
  readOnly,
  setValueState,
  status,
  style = {},
  tagName = 'input',
  value,
  rows = 1,
  name
}) => {
  const handles = {
    onBlur,
    onChange,
    onClick,
    onFocus,
    onKeyDown,
    onKeyPress,
    onKeyUp,
  }

  const atrributesField = {
    ...handles,
    className: classesBuilder(style, { input: true }),
    disabled,
    inputMode,
    max,
    maxLength,
    min,
    pattern,
    placeholder,
    readOnly,
    name,
    value: (value || '') as string,
  }

  const getField = useCallback(
    (field: HTMLInputElement | HTMLTextAreaElement): void => {
      if (autofocus && field) {
        field.focus()
      }
    },
    [autofocus],
  )

  return (
    <div className={classesBuilder(style, { field: true })}>
      {icon && iconDir !== 'right' && (
        <div className={classesBuilder(style, { iconCustom: true })}>
          <Icon name={icon} size={20} />
        </div>
      )}

      <div
        className={classesBuilder(style, {
          inputContent: true,
          readOnly,
        })}
        data-replicated-value={value}
      >
        {tagName === 'input' ? (
          <input
            ref={getField as (item: HTMLInputElement) => void}
            {...atrributesField}
            type={inputType}
          />
        ) : (
          <textarea
            ref={getField as (item: HTMLTextAreaElement) => void}
            {...atrributesField}
            rows={rows}
          />
        )}
      </div>

      {clearable && !disabled && (
        <div
          className={classesBuilder(style, { iconClerable: true })}
          onClick={() => {
            setValueState?.('')
            onClear?.()
          }}
        >
          <Icon name="close-outline" />
        </div>
      )}

      <IconStatus status={status} className={classesBuilder(style, { iconStatus: true })} />

      {icon && iconDir === 'right' && (
        <div className={classesBuilder(style, { iconCustom: true })}>
          <Icon name={icon} size={20} />
        </div>
      )}

      {contentRight}
    </div>
  )
}

export default BaseField
